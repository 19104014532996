export default {
  listMap: new Map(),
  convertTreeToList(tree, result = []) {
    let ay = result.concat();
    // 添加当前节点到结果列表中
    // result.push({ value: tree.gps_id, level });
    ay.push(tree.gps_id);
    if (tree.children && tree.children.length > 0) {
      // 遍历子节点
      for (const child of tree.children) {
        // 递归调用转换函数，增加层级并传递结果列表
        this.convertTreeToList(child, ay);
      }
    } else {
      // console.log(ay);
      for (var i = 0; i < ay.length - 1; i++) {
        if (!this.listMap.has(ay[i])) {
          this.listMap.set(ay[i], []);
        }
        this.listMap.get(ay[i]).push(ay[ay.length - 1]);
      }
    }
  },
};
