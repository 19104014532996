<template>
  <div class="back-box">
    <div style="height: 40px; margin: 5px 0px 2px 0px" class="boxx">
      <el-date-picker
        v-model="search_date"
        type="date"
        placeholder="Pick a day"
        format="YYYY-MM-DD"
        value-format="YYYY-MM-DD"
        :teleported="false"
        @change="changePicker"
        style="width: 600px"
      />
    </div>

    <div
      class="top"
      :style="{ height: 'calc(100% -' + (mapBottomHeight + 30) + 'px)' }"
    >
      <div class="row">
        <div class="row-l" :style="{ width: videoW + 'px' }">
          <GpsVideo ref="videoBack" :width="videoW" :height="videoH"></GpsVideo>
          <div class="guanbis" @click="closeVideo">
            <svg
              t="1722675774115"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="7808"
              width="16"
              height="16"
            >
              <path
                d="M825.6 217.6c-32-25.6-83.2-25.6-108.8 6.4-25.6 32-25.6 76.8 6.4 102.4 64 57.6 108.8 140.8 108.8 230.4 0 166.4-140.8 307.2-320 307.2s-320-134.4-320-307.2c0-89.6 44.8-172.8 108.8-224 32-32 32-76.8 6.4-108.8S230.4 192 198.4 217.6C102.4 300.8 38.4 422.4 38.4 556.8c0 249.6 211.2 454.4 473.6 454.4 262.4 0 473.6-204.8 473.6-454.4 0-134.4-64-256-160-339.2z m0 0"
                fill="#cccccc"
                p-id="7809"
              ></path>
              <path
                d="M512 633.6c44.8 0 76.8-32 76.8-76.8V83.2C588.8 44.8 556.8 6.4 512 6.4S435.2 38.4 435.2 83.2v473.6c0 44.8 38.4 76.8 76.8 76.8z m0 0"
                fill="#cccccc"
                p-id="7810"
              ></path>
            </svg>
          </div>
        </div>
        <div
          class="row-r"
          :style="{
            width: 'calc(100% - ' + (videoW + 5) + 'px)',
            height: videoH + 'px',
          }"
          style="overflow: hidden"
        >
          <!-- <LeafletMap ref="mainMap"></LeafletMap> -->
          <GpsMap ref="historyMap" :isPlay="false"> </GpsMap>
        </div>
      </div>
    </div>

    <div class="bottom" :style="{ height: mapBottomHeight + 'px' }">
      <el-tabs v-loading="loading" v-model="tab_act">
        <el-tab-pane :label="$t('map.shipinhuifang')" name="first">
          <GpsTimeLine
            :isLoadEvent="isLoadEvent"
            :data="timeData"
            @timeEvent="gpsTimeEvent"
          ></GpsTimeLine>
        </el-tab-pane>
        <el-tab-pane :label="$t('map.wenjianxiazai')" name="second">
          <el-table :data="dataBackVideo" border stripe height="165px">
            <el-table-column
              align="center"
              v-for="col in columns"
              :prop="col.field"
              :key="col.field"
              :label="col.label"
              :width="col.width"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column
              fixed="left"
              :label="$t('commKey.Actions')"
              width="80px"
              v-if="tableOps.length > 0"
            >
              <template #default="{ row }">
                <span class="gps-span" v-for="(op, idx) in tableOps" :key="idx">
                  <el-button
                    v-if="op.opType == 'btn'"
                    :icon="op.icon"
                    round
                    :type="op.type"
                    @click="op.click && op.click(row, callbackFun)"
                    >{{ op.name }}</el-button
                  >
                  <el-popconfirm
                    :title="$t('commKey.CONFIRMTITLE')"
                    v-if="op.opType == 'del'"
                    @confirm="op.click && op.click(row, callbackFun)"
                  >
                    <template #reference>
                      <el-button :icon="op.icon" round :type="op.type">{{
                        op.name
                      }}</el-button>
                    </template>
                  </el-popconfirm>
                </span>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog v-model="dialogVisible" title="Download">
      <el-form
        ref="ruleFormRef"
        :show-message="false"
        :model="ruleForm"
        :rules="rules"
        status-icon
        label-width="110px"
      >
        <div>
          <el-form-item
            ><el-date-picker
              v-model="dl_date"
              type="date"
              placeholder="Pick a day"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              :teleported="false"
          /></el-form-item>
        </div>
        <div>
          <el-form-item>
            <el-time-picker
              v-model="dl_startTime"
              :disabled-hours="disabledHours"
              :disabled-minutes="disabledMinutes"
              :disabled-seconds="disabledSeconds"
              placeholder="Arbitrary time"
              :teleported="false"
            />
          </el-form-item>
          <el-form-item
            ><el-time-picker
              v-model="dl_endTime"
              :disabled-hours="disabledHours"
              :disabled-minutes="disabledMinutes"
              :disabled-seconds="disabledSeconds"
              placeholder="Arbitrary time"
              :teleported="false"
          /></el-form-item>
        </div>
        <div>
          <el-form-item>
            <el-checkbox-group v-model="ch_ck" @change="change_ch">
              <el-checkbox
                v-for="i in get_ch_ay()"
                :label="i"
                :value="i"
                :key="i"
                >{{ "CH" + i }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
        </div>
      </el-form>
      <el-alert
        v-if="error_msg != ''"
        :title="error_msg"
        type="error"
        effect="dark"
        :closable="false"
      />
      <!-- <span>This is a message</span> -->
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="btnDownload"> Confirm </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import GpsVideo from "./GpsVideo.vue";
import GpsTimeLine from "./GpsTimeLine.vue";
import GpsMap from "./GpsMapHistory.vue";
import { dateFormat } from "../utils/tools";
import GpsSocket from "../utils/GpsSocket";
// import { ElMessageBox } from "element-plus";
// import { ElLoading } from "element-plus";
import moment from "moment";
import { getHTTPURL, car_dir } from "../utils/car";

// import {dateFormat} from "../utils/tools";

export default {
  components: { GpsVideo, GpsTimeLine, GpsMap },
  props: {
    dev_id: {
      type: String,
      default: "",
    },
    devID: {
      type: String,
      default: "",
    },
    cph: {
      type: String,
      default: "",
    },
    chNum: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      jia_miao: 0,
      channel_old: 0,
      loading: false,
      error_msg: "",
      max_s: 59,
      min_s: 0,
      max_m: 59,
      min_m: 0,
      max_h: 23,
      min_h: 0,
      value1: "",
      // disabledHours: [1, 2, 3, 4, 5, 6, 7, 9, 10, 11, 12],
      // disabledMinutes: [1, 2, 3],
      // disabledSeconds: [1, 2, 3],
      // ay_ch: [],
      ch_ck: [],
      ruleForm: {},
      rules: [],
      dl_startTime: "",
      dl_endTime: "",
      // max_time: "00:00:00",
      // min_time: "23:59:59",
      dl_date: "",
      dialogVisible: false,
      // search_date: moment().format("YYYY-MM-DD"),
      ct: null,
      is_play: false,
      channel_id: 1,
      isLoadEvent: false,
      select_tree_node: {},
      timeData: [],
      tab_act: "first",
      tableOps: [
        {
          opType: "btn",
          // name: "下载",
          icon: "Download",
          type: "",
          click: (row, callback) => {
            console.log(row);
            let end_time = row.end_time;
            let start_time = row.start_time;
            // console.log(end_time);
            // let channel_num = row.channel_num;

            this.dl_date = moment(start_time).format("YYYY-MM-DD");
            this.min_h = parseInt(moment(start_time).format("HH"));
            this.max_h = parseInt(moment(end_time).format("HH"));

            this.min_m = parseInt(moment(start_time).format("mm"));
            this.max_m = parseInt(moment(end_time).format("mm"));

            this.min_s = parseInt(moment(start_time).format("ss"));
            this.max_s = parseInt(moment(end_time).format("ss"));

            this.dl_startTime = moment(start_time).toDate();
            this.dl_endTime = moment(end_time).toDate();

            // this.maxTime = moment(start_time).format("HH:mm");
            // this.minTime = moment(end_time).format("HH:mm");

            // this.time_range = [moment(start_time), moment(end_time)];

            // console.log("wxw", this.dl_endTime);

            this.dialogVisible = true;
            if (this.ch_ck.length > 0) {
              this.error_msg = "";
            } else {
              this.error_msg = this.$t("report.qxztdh");
            }
          },
        },
      ],
      columns: [
        { field: "cph", label: this.$t("map.cph_name"), width: "112px" },

        {
          field: "channel_no",
          label: this.$t("map.channel_no"),
          width: "100px",
        },
        { field: "file_pos", label: this.$t("map.file_pos"), width: "100px" },
        {
          field: "start_time",
          label: this.$t("map.track_kssj"),
          width: "100px",
        },
        {
          field: "end_time",
          label: this.$t("map.track_jssj"),
          width: "100px",
        },
        { field: "file_size", label: this.$t("map.wjdx"), width: "100px" },
        { field: "alarm", label: this.$t("map.track_zt") },
      ],
      dataBackVideo: [],
      videoW: 1000,
      videoH: "calc(100vh - 300px)",
      mapBottomHeight: 250,
      search_times: [],
      startTime: "00:00:00",
      endTime: "23:59:59",
      search_date: dateFormat("YYYY-mm-dd", new Date()),
    };
  },
  mounted() {},
  methods: {
    close_timeout() {
      if (this.timer_pos) {
        clearTimeout(this.timer_pos);
      }
    },
    // PrefixZero(num, n) {
    //   return (Array(n).join(0) + num).slice(-n);
    // },
    closeVideo() {
      this.$refs.videoBack.destroy();
    },
    change_ch(ay) {
      if (ay.length > 0) {
        this.error_msg = "";
      } else {
        this.error_msg = this.$t("report.qxztdh");
      }
    },
    PrefixZero(num, n) {
      return (Array(n).join(0) + num).slice(-n);
    },
    btnDownload() {
      console.log(this.dl_startTime);
      var dev_id = this.PrefixZero(this.devID, 12);
      var dt_ss = this.dl_date.replace(/-/g, "");

      //判断开始时间不能大于结束时间啊
      var new_st =
        dt_ss + dateFormat("HH:MM:SS", this.dl_startTime).replace(/:/g, "");
      var new_et =
        dt_ss + dateFormat("HH:MM:SS", this.dl_endTime).replace(/:/g, "");
      if (new_st > new_et || new_st == new_et) {
        // ElMessageBox.alert(this.$t("report.timeError"));
        this.error_msg = this.$t("report.timeError");
        return;
      }
      var isThirty = parseInt(new_et) - parseInt(new_st);
      if (isThirty > 6000) {
        // ElMessageBox.alert(this.$t("report.timeError2"));
        this.error_msg = this.$t("report.timeError2");
        return;
      }

      var i = 0;
      var chs = this.ch_ck;
      if (chs.length == 0) {
        // ElMessageBox.alert(this.$t("report.qxztdh"));
        this.error_msg = this.$t("report.qxztdh");
        return;
      }
      this.error_msg = "";

      chs.forEach((it) => {
        let obj = {
          deviceid: dev_id,
          order: "9206",
          content: {
            ftpip: "127.0.0.1",
            ftpport: 23333,
            ftpuser: "video",
            ftppwd: "video",
            ftppath: dt_ss + "/" + dev_id,
            sourcetype: "00",
            startdate: dateFormat("YYYY-mm-dd HH:MM:SS", this.dl_startTime),
            enddate: dateFormat("YYYY-mm-dd HH:MM:SS", this.dl_endTime),
            alarmflag: "00",
            bitsstreamtype: "01",
            memorytype: "01",
            channelno: it,
            taskexecondition: "07",
          },
        };
        GpsSocket.sendMsg("order", obj);
        // GpsSocket.get_socket(obj, function (data) {
        //   console.log(data);
        // });
        this.dialogVisible = false;
      });
    },
    disabledSeconds() {
      if (this.max_m == this.min_m) {
        return this.makeRange(0, this.min_s - 1).concat(
          this.makeRange(this.max_s + 1, 59)
        );
      }
      return [];
    },
    disabledMinutes() {
      if (this.max_h == this.min_h) {
        return this.makeRange(0, this.min_m - 1).concat(
          this.makeRange(this.max_m + 1, 59)
        );
      }
      return [];
    },
    disabledHours() {
      return this.makeRange(0, this.min_h - 1).concat(
        this.makeRange(this.max_h + 1, 23)
      );
    },
    makeRange(start, end) {
      if (end < 0) return [];
      const result = [];
      for (let i = start; i <= end; i++) {
        result.push(i);
      }
      console.log("makeRange", result);
      return result;
    },
    get_ch_ay() {
      let rt = [];
      for (let i = 0; i < this.chNum; i++) {
        rt.push(i + 1);
      }
      return rt;
    },
    init_socket() {
      GpsSocket.addListener((data) => {
        console.log(data);
        if (data.type == "realtime") {
          if (
            typeof data.content.deviceid != "undefined" &&
            data.content.deviceid.indexOf(this.devID) > -1 &&
            typeof data.content.content !== "undefined" &&
            typeof data.content.content.AVSourceList !== "undefined" &&
            data.content.content.AVSourceList
          ) {
            this.loading = false;
            var ay = data.content.content.AVSourceList;
            var channel_obj = {};
            var channel_key = "";
            var channel_num = 0;

            var file_pos_idx = 0;

            var ay_pos = {
              a0: this.$t("map.zhongduanshebei"),
              a1: this.$t("map.fanyi98"),
            };

            var ay_files = [];
            for (var i = 0; i < ay.length; i++) {
              channel_key = "CH" + ay[i].ChannelNo;
              if (typeof channel_obj[channel_key] === "undefined") {
                channel_obj[channel_key] = [];
                channel_num++;
              }
              channel_obj[channel_key].push(ay[i]);

              if (parseInt(ay[i].FileSize) > 0) {
                // chs.push(ay[i].ChannelNo);
                ay_files.push({
                  file_id: i + 1,
                  cph: this.cph,
                  dev_id: this.devID,
                  channel_num: ay[i].ChannelNo,
                  channel_no: "CH" + ay[i].ChannelNo,
                  start_time: ay[i].StartDate.replace("T", " "),
                  end_time: ay[i].EndDate.replace("T", " "),
                  alarm:
                    ay[i].AlarmFlag > 0
                      ? this.$t("map.baojing")
                      : this.$t("map.zhengchang"),
                  file_size:
                    (parseFloat(ay[i].FileSize) / 1024 / 1204).toFixed(2) + "M",
                  file_pos: ay_pos["a" + file_pos_idx],
                });
              }
            }

            this.dataBackVideo = ay_files;
            let timeDataArray = [];
            let timeTempArray = [];
            let s_ay, e_ay;
            for (let p in channel_obj) {
              timeTempArray = [];
              channel_obj[p].forEach((it) => {
                s_ay = it.StartDate.split("T");
                e_ay = it.EndDate.split("T");
                timeTempArray.push({
                  start_time: s_ay[s_ay.length - 1],
                  end_time: e_ay[e_ay.length - 1],
                  ch: p.replace("CH", ""),
                });
              });
              timeDataArray.push({ name: p, times: timeTempArray });
            }
            console.log("timeDataArray", timeDataArray);
            this.timeData = timeDataArray;
          }
        } else if (data.type == "answer") {
          if (this.is_play) {
            this.is_play = false;
            let url = this.get_video_url(this.devID);
            // console.log(url, this.$refs.video);
            setTimeout(() => {
              this.$refs.videoBack.playUrl(url);
            }, 1000);
          }
        }
        // console.log("hcn", res);
      });

      setTimeout(() => {
        this.isLoadEvent = !this.isLoadEvent;
      }, 1000);
    },
    changePicker(date) {
      // console.log(date);
      this.search_date = date;
      this.search_data();
    },
    get_video_url(sn) {
      let video_ip_play = this.$store.getters.videoIp; //"test.cnms365.com";
      let video_port_play = this.$store.getters.videoPort; //4022;
      return (
        "https://" +
        video_ip_play +
        ":4022/flv?port=10077&app=live&stream=" +
        this.PrefixZero(sn, 11) +
        "_channel_" +
        this.channel_id +
        "_playback"
      );
      // return (
      //   "https://" +
      //   video_ip_play +
      //   ":" +
      //   video_port_play +
      //   "/flv?port=10077&app=live&stream=" +
      //   sn +
      //   "_channel_" +
      //   this.channel_id
      // );
    },
    setMapCar() {
      //获得定位
      this.$api
        .search_car_track_info({
          clickTime: this.ct + ++this.jia_miao,
          deviceid: this.dev_id,
          cph: this.cph,
        })
        .then((res) => {
          let row = res.data.data;
          if (row) {
            console.log("setMapCar", row);
            this.$refs.historyMap.posMarker({
              id: this.dev_id,
              lng: row.pLongitude,
              lat: row.pLatitude,
              html:
                "<b>" +
                this.$t("map.track_cph") +
                "：</b>" +
                row.cph +
                "<br><b>" +
                this.$t("map.track_sj") +
                "：</b>" +
                row.pGpsTime,
              icon:
                getHTTPURL() +
                "public/tree/dxtree/imgs/vehicle/2/online/" +
                car_dir(row.pDirection) +
                ".png",
            });
            this.timer_pos = setTimeout(() => {
              this.setMapCar();
            }, 1000);
          }

          // console.log("ssss", res.data);
        });
    },
    gpsTimeEvent(obj) {
      this.$refs.videoBack.destroy();

      console.log("gpsTimeEvent", obj);
      this.channel_id = parseInt(obj.ch);
      let ct = parseInt(
        this.getDateFromString(
          this.search_date + " " + obj.clickTime
        ).getTime() / 1000
      );
      this.ct = ct;
      this.jia_miao = 0;

      if (this.timer_pos) {
        clearTimeout(this.timer_pos);
      }

      this.timer_pos = setTimeout(() => {
        this.setMapCar();
      }, 1000);

      //回放视频
      let sn = this.devID;
      let video_ip = this.$store.getters.orderIp; //"www.xilihuala.top";
      let order_port = this.$store.getters.videoPlaybackPort; //"9500";
      // let video_ip_play = this.$store.getters.videoIp; //"test.cnms365.com";
      // let video_port_play = this.$store.getters.videoPort; //4022;
      let dt = this.search_date.replace(/-/g, "").substr(2);
      let channel = parseInt(obj.ch);
      let st = dt + obj.clickTime.replace(/:/g, "");
      let et = dt + obj.end_time.replace(/:/g, "");
      this.is_play = true;

      // if (this.channel_old > 0) {
      //   if (this.channel_old != channel) {
      //发送关闭
      GpsSocket.sendMsg("order", {
        deviceid: sn /*"018124567840"*/,
        order: "9202",
        content: channel + ",2,0,000000000000",
      });
      //   }
      // }
      GpsSocket.sendMsg("order", {
        deviceid: sn /*"018124567840"*/,
        order: "9201",
        content:
          video_ip +
          "," +
          order_port +
          ",0," +
          channel +
          ",0,1,1,0,0," +
          st +
          "," +
          et +
          "",
      });
      this.channel_old = channel;
    },
    getDateFromString(str) {
      var reg = /^(\d+)-(\d+)-(\d+) (\d+):(\d+):(\d+)/;
      var s = str.match(reg);
      var result = "";
      if (s) {
        result = new Date(s[1], s[2] - 1, s[3], s[4], s[5], s[6]);
      }
      return result;
    },
    search_data() {
      this.init_socket();
      let day = this.search_date;
      let dt = this.search_date.replace(/-/g, "").substr(2);
      let st = this.startTime.replace(/:/g, "");
      let et = this.endTime.replace(/:/g, "");

      let sn = this.devID; //this.devID;
      this.is_play = false;
      this.loading = true;
      GpsSocket.sendMsg("order", {
        deviceid: sn,
        order: "9205",
        content: "0," + (dt + st) + "," + (dt + et) + ",0,0,1,1",
      });
    },
    getWinSize() {
      let height = parseFloat(window.innerHeight) - this.mapBottomHeight - 50;
      this.videoH = height;
    },
  },
};
</script>

<style lang="scss" scoped>
.back-box {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.top {
  position: relative;
  z-index: 999;
}
::v-deep .el-table th.el-table__cell {
  background: #eef3f8;
  color: #333;
}
::v-deep .el-table--border .el-table__cell {
  border-right: 1px solid #fafafa;
}
.boxx {
  text-align: center;
}
::v-deep .boxx .el-input--small .el-input__wrapper {
  color: #2c78bf;
  background: #fff;
  color: #333;
  border: 1px solid #2c78bf;
  height: 30px;
  border-radius: 20px;
  margin-bottom: 20px;
}
//::v-deep .tool{position:absolute;z-index:999;top:10px;right:10px;background:#fff;border:1px solid #ccc;font-size:14px;}
::v-deep .boxx .el-icon svg {
  color: #2c78bf !important;
}
::v-deep .boxx .el-input--small .el-input__inner {
  color: #555 !important;
  font-weight: bold;
}
::v-deep #container {
  height: calc(100vh - 300px);
}
.row-l {
  position: relative;
}
.guanbis {
  position: absolute;
  bottom: 11px;
  z-index: 9999;
  color: #fff;
  right: 10px;
  cursor: pointer;
}
::v-deep .jessibuca-controls-right {
  margin-right: 15px;
}
</style>
