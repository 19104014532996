<template>
  <div class="g-map">
    <div class="tool">
      <div class="map-change flex-row">
        <div class="map-tool map-tool-1" @click="draw(6)"></div>
        <div class="map-tool map-tool-2" @click="draw(1)"></div>
        <div class="map-tool map-tool-3" @click="draw(2)"></div>
        <div class="map-tool map-tool-4" @click="draw(4)"></div>
        <div class="map-tool map-tool-5" @click="draw(3)"></div>
        <div class="map-tool map-tool-6" @click="draw(5)"></div>

        <div v-for="(map, index) in maps" :key="index" @click="clickMap(map)">
          {{ map.name }}
        </div>
      </div>
    </div>
    <component
      ref="mapRef"
      :is="currentMap.component"
      :api-key="currentMap.apiKey"
      :ayMarker="ayMarker"
      :ayData="ay_data"
    ></component>
    <div></div>
  </div>
</template>

<script>
import BaiduMap from "../components/GpsMap/BaiduMap.vue";
import GoogleMap from "../components/GpsMap/GoogleMap.vue";
import { markRaw } from "vue";

export default {
  data() {
    return {
      mapID: "map-container",
      // map_markers: new Map(),
      ay_data: [],

      ayMarker: [], //lat纬度：-90~90，北纬正数，南纬负数  lng经度：-180~180，东经正数，西经负数
      currentMap: {
        name: this.$t("map.guge"),
        component: markRaw(GoogleMap),
        apiKey: "your_google_map_api_key",
        // name: this.$t("map.baidu"),
        // component: markRaw(BaiduMap),
        // apiKey: "your_baidu_map_api_key",
      },
      maps: [
        {
          id: 1,
          name: this.$t("map.guge"),
          component: markRaw(GoogleMap),
          apiKey: "your_google_map_api_key",
        },
        {
          id: 2,
          name: this.$t("map.baidu"),
          component: markRaw(BaiduMap),
          apiKey: "your_baidu_map_api_key",
        },

        // {
        //   name: "Bing Map",
        //   component: BingMap,
        //   apiKey: "your_bing_map_api_key",
        // },
        // {
        //   name: "Tianditu Map",
        //   component: TiandituMap,
        //   apiKey: "your_tianditu_map_api_key",
        // },
      ],
      currentMapIndex: 0,
    };
  },
  created() {
    this.ayMarker = [{ lat: 39.915, lng: 116.404 }];
  },
  mounted() {
    this.$api.sys_get_xtpz({}).then((res) => {
      let row = res.data.row;
      this.mainMap(parseInt(row.default_map));
      // console.log(res.data);
    });
  },
  methods: {
    setCurrCar(id) {
      this.$refs["mapRef"].setCurrCar(id);
    },
    mainMap(id) {
      let it = this.maps.find((it) => it.id == id);
      this.currentMap = it;
    },
    posMarker(obj) {
      console.log("posMarker", obj);
      this.$refs["mapRef"].posMarker(obj);
    },
    removeMaerker(obj) {
      console.log("removeMaerker", obj);
      for (let i = this.ay_data.length - 1; i >= 0; i--) {
        if (this.ay_data[i].id == obj.id) {
          this.ay_data.splice(i, 1);
        }
      }
      this.$refs["mapRef"].removeMaerker(obj);
      // let key = "m" + obj.id;
      // if (this.map_markers.has(key)) {
      //   this.map_markers.delete(key);
      // }
      // console.log("removeMaerker", this.map_markers);
      // this.$refs["mapRef"].setMarkers(this.map_markers);
    },
    addMarker(obj, is_pos = 1) {
      console.log("addMarker", obj);
      this.ay_data.push(obj);
      this.$refs["mapRef"].addMarker(obj, is_pos);
      // let key = "m" + obj.id;
      // if (!this.map_markers.has(key)) {
      //   this.map_markers.set(key, obj);
      // }
      // this.$refs["mapRef"].setMarkers(this.map_markers);
      // console.log("addMarker", this.map_markers);
    },
    draw(drawType) {
      this.$refs["mapRef"].drawModel(drawType);
    },
    clickMap(it) {
      this.currentMap = it;
      // this.ayMarker.splice(0, this.ayMarker.length);
      // this.ayMarker = [{ lat: 39.915, lng: 116.404 }];
    },
  },
};
</script>

<style lang="scss">
.car_info {
  width: 400px;
  box-sizing: border-box;
}

.car_info h2 {
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.car_info dl {
  clear: both;
  height: 20px;
  line-height: 20px;
  display: block;
}

.car_info dt {
  width: 25px;
  float: left;
  text-align: justify;
  text-justify: distribute-all-lines;
  text-align-last: justify;
  -moz-text-align-last: justify; /*ff*/
  -webkit-text-align-last: justify; /*chrome 20+*/
}

.car_info dd {
  width: 145px;
  float: left;
  margin-left: 5px;
}
.car_info_item {
  line-height: 18px;
  label {
    padding-left: 10px;
    float: left;
  }
}
// .car_info_item_span label {
//   width: 60px;
//   float: left;
// }
// .car_info_item label {
//   float: left;
// }
</style>
<style lang="scss" scoped>
.g-map {
  width: 100%;
  height: 100%;
}
.tool {
  display: none;
  position: absolute;
  z-index: 999;
  top: 10px;
  right: 10px;
  background: #fff;
  border: 1px solid #ccc;
  font-size: 14px;
  .flex-row {
    display: flex;
    flex-direction: row;
  }
  .map-change {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 10px;
    div {
      padding: 0 5px;
    }
    .map-tool {
      box-sizing: border-box;
      background: url(../assets/bg_drawing_tool.png) no-repeat 0 0;
      // background-size: 100% 100%;
      width: 50px;
      height: 40px;
    }
    .map-tool-1 {
      background-position: -10px -2px;
    }
    .map-tool-2 {
      background-position: -65px -2px;
    }
    .map-tool-3 {
      background-position: -138px -2px;
    }
    .map-tool-4 {
      background-position: -200px -2px;
    }
    .map-tool-5 {
      background-position: -265px -2px;
    }
    .map-tool-6 {
      background-position: -330px -2px;
    }
  }
}
</style>
